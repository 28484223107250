<template>
  <div class="buyEnWrap en">
    <m-layout-bar>
      <div class="buyWrap">
        <div class="bannerWrap">
          <img :src="require('../assets/image/buy_en/banner.png')" alt />
        </div>
        <div class="contentWrap">
          <div class="layoutLeft shadowBox_center">
            <ul class="tabWrap">
              <li
                class="item menuWrap shouBanLi maskWrap size toShow"
                :class="{'active':activeIndex=='0'}"
                data-index="0"
                @click="clickHandle"
              >
                <router-link to="/buy_en/garageKits">
                  <img :src="require('../assets/image/buy_en/item_1.png')" alt />
                </router-link>
              </li>
              <li
                class="item menuWrap IP maskWrap size toShow"
                :class="{'active':activeIndex=='1'}"
                data-index="1"
                @click="clickHandle"
              >
                <router-link to="/buy_en/ip_en">
                  <img :src="require('../assets/image/buy_en/item_2.png')" alt />
                </router-link>
              </li>
              <li
                class="item menuWrap kangYang maskWrap size toShow"
                :class="{'active':activeIndex=='2'}"
                data-index="2"
                @click="clickHandle"
              >
                <router-link to="/buy_en/health">
                  <img :src="require('../assets/image/buy_en/item_3.png')" alt />
                </router-link>
              </li>
              <li
                class="item menuWrap yangYan maskWrap size toShow"
                :class="{'active':activeIndex=='3'}"
                data-index="3"
                @click="clickHandle"
              >
                <router-link to="/buy_en/beauty">
                  <img :src="require('../assets/image/buy_en/item_4.png')" alt />
                </router-link>
              </li>
            </ul>
          </div>
          <div class="layoutRight">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </m-layout-bar>
  </div>
</template>

<script type="text/ecmascript-6">
import LayoutBar from "@/components/LayoutBar_en";
import TitleGapBar from "@/components/TitleGapBar";
import PicSummaryBar from "@/components/picSummaryBar";
// import { checkIsNumber } from "@/utils/util.js";

export default {
  components: {
    [LayoutBar.name]: LayoutBar,
    [TitleGapBar.name]: TitleGapBar,
    [PicSummaryBar.name]: PicSummaryBar
  },
  data() {
    return {
      menuList: [
        {
          num: 1,
          name: "蛋卷",
          src: "../assets/image/buy/banShouLi_1.png.png",
          detail:
            "村域面积达2.13平方公里，是一个主要以铁皮石斛为核心，集合主题游乐、田园休闲、旅居度假、科普教育为,铁定溜溜位于温州市大荆镇，村域面积达2.13平方公里"
        },
        {
          num: 2,
          name: "蛋卷",
          src: "../assets/image/buy/banShouLi_1.png.png",
          detail:
            "村域面积达2.13平方公里，是一个主要以铁皮石斛为核心，集合主题游乐、田园休闲、旅居度假、科普教育为,铁定溜溜位于温州市大荆镇，村域面积达2.13平方公里"
        },
        {
          num: 3,
          name: "年轮蛋糕",
          src: "../assets/image/buy/banShouLi_1.png.png",
          detail: "村域面积达2.13平方公里。"
        }
      ],
      barFixed: false,
      activeName: "shouBanLi",
      activeIndex: "0"
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    let path = this.$route.path;
    if (path.indexOf("garageKits") > 0) {
      this.activeIndex = "0";
    } else if (path.indexOf("ip_en") > 0) {
      this.activeIndex = "1";
    } else if (path.indexOf("health") > 0) {
      this.activeIndex = "2";
    } else if (path.indexOf("beauty") > 0) {
      this.activeIndex = "3";
    }
  },
  methods: {
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (!document.querySelector("#mShouBanLi")) return;
      let distOffsetTop = document.querySelector("#mShouBanLi").offsetTop;

      if (parseInt(distOffsetTop).toString() === "NaN") {
        return;
      }
      if (scrollTop > distOffsetTop - 100) {
        this.barFixed = true;
      } else {
        this.barFixed = false;
      }

      //滚动到"手办礼"的范围700
      if (scrollTop > 700) {
        this.activeName = "shouBanLi";
      }
      // 滚动到“IP萌物”的范围1400
      if (scrollTop > 1400) {
        this.activeName = "ip";
      }

      //滚动到“康养佳品”的范围2116
      if (scrollTop > 2116) {
        this.activeName = "kangYang";
      }

      //滚动到“美颜美物”的范围2816
      if (scrollTop > 2816) {
        this.activeName = "meiWu";
      }

      // console.log("window scrollTop:", scrollTop);
      // console.log("目标 distOffsetTop:", distOffsetTop);
      // console.log("fixed标识：", this.barFixed);
    },
    goNavArea(anchorId) {
      let anchorElement = document.getElementById(anchorId);
      if (anchorElement) {
        anchorElement.scrollIntoView();
      }
    },
    // 点击按钮，实现选中效果
    clickHandle(e) {
      // console.log('1');
      // console.log(e.currentTarget.getAttributeNode("data-name"));
      if (!e.currentTarget.getAttributeNode("data-index")) return;
      this.activeIndex = e.currentTarget.getAttributeNode("data-index").value;
      if (!this.activeIndex) {
        this.activeIndex = "";
      }
      // this.goNavArea(anchorId);
    }
  }
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";
.buyWrap {
  margin-bottom: 100px;
  border-bottom: 1px solid transparent;

  > .bannerWrap {
    width: 100%;
    height: 426px;
  }
  > .contentWrap {
    width: 1400px;
    margin: 30px auto 0;
    > .layoutLeft {
      display: inline-block;
      width: 319px;
      // height:522px;
      padding: 41px 35px 0 41px;
      vertical-align: top;
      margin-right: 77px;
      box-sizing: border-box;
      border-radius: 10px;

      .item {
        position: relative;
        > a {
          display: block;
          width: 251px;
          height: 84px;
          margin-bottom: 50px;
          > img {
            width: 100%;
            height: 100%;
          }
        }
      }

      > .tabWrap {
        .menuWrap.maskWrap.size.toShow:after {
          display: none;
        }

        .menuWrap.maskWrap.size.toShow:before {
          width: 249px;
          height: 6px;
          bottom: -15px;
          left: 0;
          border-radius: 5px;
        }

        .menuWrap.shouBanLi.maskWrap.size.toShow:before {
          background: rgba(87, 180, 112, 0);
        }

        .menuWrap.shouBanLi.maskWrap.size.toShow:hover:before,
        .menuWrap.shouBanLi.maskWrap.size.toShow.active:before {
          background: rgba(87, 180, 112, 1);
        }

        .menuWrap.IP.maskWrap.size.toShow:before {
          background: rgba(245, 194, 95, 0);
        }

        .menuWrap.IP.maskWrap.size.toShow:hover:before,
        .menuWrap.IP.maskWrap.size.toShow.active:before {
          background: rgba(245, 194, 95, 1);
        }

        .menuWrap.kangYang.maskWrap.size.toShow:before {
          background: rgba(113, 209, 191, 0);
        }

        .menuWrap.kangYang.maskWrap.size.toShow:hover:before,
        .menuWrap.kangYang.maskWrap.size.toShow.active:before {
          background: rgba(113, 209, 191, 1);
        }

        .menuWrap.yangYan.maskWrap.size.toShow:before {
          background: rgba(75, 158, 213, 0);
        }

        .menuWrap.yangYan.maskWrap.size.toShow:hover:before,
        .menuWrap.yangYan.maskWrap.size.toShow.active:before {
          background: rgba(75, 158, 213, 1);
        }
      }
    }
    > .layoutRight {
      display: inline-block;
      width: 1003px;
      min-height: 500px;
      vertical-align: top;
      // background:red;
    }
  }
}
</style>
