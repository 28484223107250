<template>
  <!-- maskWrap leftToRight -->
  <!-- :style="{'--imgWrapBg':'url('+coverImgUrl+')'}" -->

  <div class="picSummaryWrap">
    <div class="picWrap" :class="{'shadowBox':isShadow}" :style="{'--imgWrapBg':'url('+src+')'}">
      <div class="imgWrap" :style="imgWrapStyle">
        <img :src="src" alt />
      </div>
      <div class="decIcon" :class="typeObject.className">{{typeObject.txt}}</div>
      <div class="titleWrap" :style="titleWrapStyle">
        <span class="title textline1" :style="titleStyle">{{title}}</span>
      </div>
      <div class="detail textline3" :style="detailWrap">{{detail}}</div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src;
export default {
  name: "m-pic-summary-bar",
  props: {
    src: {
      type: String
      // default:"../assets/image/home/tieDingLiuLiu_2.png"
    },
    itemtype: {
      type: String,
      default: ""
    },
    isShadow: {
      type: Boolean,
      default: true
    },
    title: {
      type: String
      // default:'名称'
    },
    detail: {
      type: String
      // default:'详情描述'
    },
    picWrapborderRadius: {
      type: Number,
      default: 5
    },
    imgWidth: {
      type: Number,
      default: 450
    },
    imgHeight: {
      type: Number,
      default: 450
    },
    titlePaddingTop: {
      type: Number,
      default: 25
    },
    titlePaddingLeft: {
      type: Number,
      default: 16
    },
    titleFontSize: {
      type: Number,
      default: 19
    },
    titleFontColor: {
      type: String,
      default: "#3e3e3e"
    },
    titleMarginBottom: {
      type: Number,
      default: 0
    },
    detailHeight: {
      type: Number,
      default: 54
    },
    detailFontSize: {
      type: Number,
      default: 13
    },
    detailFontColor: {
      type: String,
      default: "#878787"
    },
    detailMarginTop: {
      type: Number,
      default: 23
    },
    detailMarginLeft: {
      type: Number,
      default: 16
    },
    detailMarginRight: {
      type: Number,
      default: 16
    },
    detailMarginBottom: {
      type: Number,
      default: 24
    }
  },
  data() {
    return {
      decTxt: ""
    };
  },
  computed: {
    typeObject() {
      let className = "";
      let txt = "";
      let obj = {};
      if (this.itemtype == "伴手礼") {
        className = "shouBanLi";
        txt = "必备伴手礼";
      } else if (this.itemtype == "IP萌物") {
        className = "ip";
        txt = "IP萌物";
      } else if (this.itemtype == "康养佳品") {
        className = "kangYang";
        txt = "康养佳品";
      } else if (this.itemtype == "养颜美物") {
        className = "meiWu";
        txt = "养颜美物";
      }
      obj.className = className;
      obj.txt = txt;
      return obj;
    },
    picWrapStyle() {
      let obj = {
        width: this.imgWidth + "px",
        borderRadius: this.picWrapborderRadius + "px"
      };
      return obj;
    },
    imgWrapStyle() {
      let obj = {
        width: this.imgWidth + "px",
        height: this.imgHeight + "px"
      };
      return obj;
    },
    titleWrapStyle() {
      let obj = {
        paddingTop: this.titlePaddingTop + "px",
        paddingLeft: this.titlePaddingLeft + "px"
      };
      return obj;
    },
    titleStyle() {
      let obj = {
        fontSize: this.titleFontSize + "px",
        color: this.titleFontColor,
        marginBottom: this.titleMarginBottom
      };
      return obj;
    },
    detailWrap() {
      let obj = {
        height: this.detailHeight + "px",
        fontSize: this.detailFontSize + "px",
        color: this.detailFontColor,
        marginTop: this.detailMarginTop + "px",
        marginLeft: this.detailMarginLeft + "px",
        marginRight: this.detailMarginRight + "px",
        marginBottom: this.detailMarginBottom + "px"
      };
      return obj;
    }
  }
  // mounted() {
  //   this.coverImgUrl = require(this.src);
  // }
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";

.picWrap:after {
  content: "微信扫一扫立即获取美物";
  position: absolute;
  width: 100%;
  height: 100%;
  line-height: 710px;
  vertical-align: center;
  top: 0;
  left: 0;
  color: #fff;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  white-space: pre;
  background-image: url(~@/assets/image/buy/code2.png);
  background-color: rgba(0, 0, 0, 0.5);
  background-repeat: no-repeat;
  background-position: 50% 40%;
  z-index: 999;
  // transition: all 0.2s ease-in-out;
  display: none;
}

// hover时候把内容图显示成封面图
.picWrap:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  // line-height: 750px;;
  top: 0;
  left: 0;
  color: #fff;
  // font-size: 33px;
  font-weight: bold;
  text-align: center;
  background-image: var(--imgWrapBg);
  // background-image: url(~@/assets/image/buy/g1_2_h.png);
  background-color: rgba(0, 0, 0, 0.5);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 100% 100%;
  z-index: 999;
  // transition: all 0.2s ease-in-out;
  display: none;
}

.picWrap:hover:after,
.picWrap:hover:before {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}
.picSummaryWrap {
  transition: all 0.3s ease-in;
}
.picWrap {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  width: 440px;
  height: 445px;
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;

  cursor: pointer;
  // height: 295px;
  .imgWrap {
    display: inline-block;
    > img {
      width: 100%;
      height: 100%;
    }
  }
  .decIcon {
    position: absolute;
    top: 0;
    right: 0;
    width: 107px;
    height: 37px;
    line-height: 37px;
    color: $main-white;
    text-align: center;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;

    &.shouBanLi {
      background: $bg-green;
    }
    &.ip {
      background: $bg-yellow;
    }
    &.kangYang {
      background: $bg-greengreen;
    }
    &.meiWu {
      background: $bg-blueDark;
    }
  }
  .titleWrap {
    .title {
      display: block;
      // font-size: 36px;
      font-weight: bold;
    }
  }
  .detail {
    line-height: 1.5;
    overflow: hidden;
  }
}
</style>